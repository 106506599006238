<template>
  <v-list v-if="isRegional">
    <v-list-item :to="{name: 'RegionalClientListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-account-supervisor'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Клиенты'" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-list v-else-if="isDealer">
    <v-list-item :to="{ hash: 'InstructionsDealerDialog' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-text-box-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Инструкция'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ hash: 'InstructionsApiDialog' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-api'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Подключение API'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{name: 'DealerClientListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-account-supervisor'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Клиенты'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{name: 'DealerTaskListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-inbox-multiple'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Заявки'" />
      </v-list-item-content>
    </v-list-item>
    <v-subheader>Обновления</v-subheader>
    <v-list-item :to="{name: 'DealerStatusesListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-history'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Статусы'" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-list v-else>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.InstructionsDialog)" :to="{ hash: 'InstructionsDialog' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-text-box-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Инструкция'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="userParams && userParams.navigation && userParams.navigation.InnCheckPage" :to="{ name: 'InnCheckPage' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-home-search-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Проверка ИНН'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.InstructionsApiDialog)" :to="{ hash: 'InstructionsApiDialog' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-api'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Подключение API'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.ClientListPage)" :to="{name: 'ClientListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-account-supervisor'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Клиенты'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.TaskListPage)" :to="{name: 'TaskListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-inbox-multiple'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Заявки'" />
      </v-list-item-content>
    </v-list-item>
    <template v-if="(userParams && userParams.navigation && (userParams.navigation.AuditListPage || userParams.navigation.AuditResultListPage))">
      <v-subheader>Аудит</v-subheader>
      <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.AuditListPage)" :to="{name: 'AuditListPage'}">
        <v-list-item-icon>
          <v-icon v-text="'mdi-clipboard-text-search'" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Реестр'" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="(userParams && userParams.navigation && userParams.navigation.AuditResultListPage)" :to="{name: 'AuditResultListPage'}">
        <v-list-item-icon>
          <v-icon v-text="'mdi-text-box-check'" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Результаты'" />
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-subheader v-if="accessToNews">Обновления</v-subheader>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.DownloadsListPage)" :to="{name: 'DownloadsListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-download'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Загрузки'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.PromotionsListPage)" :to="{name: 'PromotionsListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-alert-circle-check-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Акции B2C'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && userParams.navigation && userParams.navigation.RelevanceListPage)" :to="{name: 'RelevanceListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-point-of-sale'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Актуальность продаж'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && userParams.navigation && userParams.navigation.AdvanceClientList)" :to="{name: 'AdvanceClientList'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-percent-box'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Авансовый Статус'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.StopListPage)" :to="{name: 'StopListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-alert-octagon'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Стоп-лист'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.StatusesListPage)" :to="{name: 'StatusesListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-history'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Статусы'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="(userParams && !userParams.navigation) || (userParams && userParams.navigation && userParams.navigation.ClassificationsListPage)" :to="{name: 'ClassificationsListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-clipboard-text-clock-outline'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Классификации'" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Navigation',
  computed: {
    ...mapGetters({
      access: 'access',
      user: 'user/id',
      userParams: 'user/userParams',
      accessAuditor: 'user/accessAuditor',
      accessDealer: 'user/accessDealer',
      accessRegional: 'user/accessRegional',
    }),
    isDealer() {
      return this.accessDealer;
    },
    isRegional() {
      return this.accessRegional;
    },
    accessToNews() {
      return this.userParams && this.userParams.navigation &&
          (
              this.userParams.navigation.RelevanceListPage
              || this.userParams.navigation.StatusesListPage
              || this.userParams.navigation.ClassificationsListPage
              || this.userParams.navigation.DownloadsListPage
          )
    }
  },
}
</script>

<style module lang="scss"></style>
