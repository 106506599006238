<template>
  <div :class="$style.root">
    <v-main :class="$style.main">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-navigation-drawer
        v-if="isAuthenticated"
        :value="drawer"
        :mini-variant="min"
        app
        width="200"
        stateless
        mobile-breakpoint="0"
    >
      <logo v-if="!min" />
      <v-list>
        <v-list-item @click="min = !min">
          <v-list-item-icon>
            <v-icon v-text="['mdi-chevron-left', 'mdi-menu'][+min]" />
          </v-list-item-icon>
          <v-list-item-title v-text="'Свернуть'" />
        </v-list-item>
      </v-list>
      <v-divider />
      <question-nav />
      <v-divider />
      <navigation />
      <v-divider />
      <user-bar :min-variant="min" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Logo from '@/components/Logo/Logo';
import UserBar from '@/components/UserBar/UserBar';
import Navigation from '@/components/Navigation/Navigation';
import QuestionNav from '@/components/QuestionNav/QuestionNav';

export default {
  name: 'default',
  components: {
    Logo,
    Navigation,
    UserBar,
    QuestionNav
  },
  data() {
    return {
      drawer: true,
      min: this.$vuetify.breakpoint.mobile,
    };
  },
  computed: {
    isAuthenticated() {
      return ![
        'UserSignInPage',
        'UserRecoveryPage',
        'UserRecoverySuccessPage',
        'UserPasswordChangePage',
      ].includes(this.$route.name);
    },
  },
}

// Защита от копирования
document.oncopy = function(){
  const body = document.getElementsByTagName('body')[0];
  const selection = window.getSelection();
  const div = document.createElement('div');

  if(selection.baseNode.parentElement!=selection.extentNode.parentElement) {
    div.style.position = 'absolute';
    div.style.left = '-99999px';
    body.appendChild(div);
    div.innerHTML = ' ';
    selection.selectAllChildren(div);

    window.setTimeout(function(){
      body.removeChild(div);
    }, 0);
  }
}
</script>

<style module lang="scss">
.root {
  height: 100%;
  .main {
    height: 100%;
    background: #F5F5F5;
  }
}
</style>
